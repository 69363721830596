import React, { FunctionComponent} from 'react'
import './resumecontent.css'


type Props={
    actif: boolean,
    offset: boolean,
  }

const Experience: FunctionComponent<Props> = ({actif, offset}) => {
    

    return (
        <div id="resumeWindow" className={(actif ? "show":"")}>

                <div className="boule show"></div>
                    {/* Left */}
                <div className="show d-flex flex-row w-100">

                        <div className="expItemLeft show">
                            <div className="expHolderLeft show">
                                <div className="head show">
                                    <div className="date-range show">3/19 - 9/19</div>
                                        <h3><b>Digital Project Manager</b></h3>
                                        <h4>Enedis - Paris, France</h4>
                                    </div>
                                    <div className="body show">
                                    <p><b>From an idea to a digital solution</b></p>
                                    <p>Worked directly with the colaborator to create new digital solution to solve their problems</p>
                                     </div>
                                </div>
                            </div>
                </div>

                    {/* Right */}                
                    <div className="show d-flex flex-row-reverse">
                        <div className={(offset ? "expItemRight show leftMinus":"expItemRight show")}>
                            <div className="expHolderRight show">
                                <div className="head show">
                                    <div className="date-range show">10/18 - 3/19</div>
                                        <h3><b>Project Manager</b></h3>
                                        <h4>Parrot Drones - Paris, France</h4>
                                    </div>
                                    <div className="body show">
                                    <p><b>Worked with a team of 10+ of engineer as project manager</b></p>
                                    <p>Conception of a new smart battery : Electronic, software & mechanic
                                    </p>
                                     </div>
                                </div>
                            </div>
                </div>
                    {/* Left */}
                    <div className="show d-flex flex-row">
                        <div className="expItemLeft show">
                            <div className="expHolderLeft show">
                                <div className="head show">
                                    <div className="date-range show">2/18 - 7/18</div>
                                        <h3><b>Digital Project Manager</b></h3>
                                        <h4>Enedis - Paris, France</h4>
                                    </div>
                                    <div className="body show">
                                    <p><b>Management of a board of project</b></p>
                                    <p>Using design thinking method to understand the problem of the client to match with the solution. 
                                    </p>
                                     </div>
                                </div>
                            </div>
                </div>
            </div>
        


            

    );
};

export default Experience;

