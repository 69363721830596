import React, { FunctionComponent} from 'react'
import './resumecontent.css'
import DonutChart from './donuts';

import certified from "../../ico/icons8-guarantee-24.png";



type Props={
    actif: boolean,
  }

const Skills: FunctionComponent<Props> = ({actif}) => {
    

    return (
        <div className={(actif ? "show":"")}>
                <div className="show container-fluid">
                    
                    <div className="show row">
                        <div className="show col-3">
                            <div className="show row">
                                <div className="show col-12 m-2 skillsTitle">
                                    <h3>Electronics</h3>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row">
                                <div className="show col-12 m-2 skillsTitle">
                                    <h3>Project Management</h3>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row">
                                <div className="show col-12 m-2 skillsTitle">
                                    <h3>Web & mobile</h3>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row">
                                <div className="show col-12 m-2 skillsTitle">
                                    <h3>Industrial Design</h3>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="show row mt-5">
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={90} mainColor={'EB4558'} secondColor={'991626'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Prototyping</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={90} mainColor={'5BCE3D'} secondColor={'2C8614'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Agile PM</b><img src={certified} alt="Certified"/></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                    <div className="show col-6"><DonutChart value={95} mainColor={'F58E48'} secondColor={'9F4E17'}/></div>
                                    <div className="show col-6 d-flex align-items-center justify-content-center"><b>HTML/CSS</b></div>
                                </div>
                            </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={90} mainColor={'2E9B89'} secondColor={'0F6556'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Solidworks</b><img src={certified} alt="Certified"/></div>
                            </div>
                        </div>
                    </div>



                    <div className="show row mt-5">
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={85} mainColor={'F58E48'} secondColor={'9F4E17'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Arduino</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={85} mainColor={'2E9B89'} secondColor={'0F6556'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Scrum</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                    <div className="show col-6"><DonutChart value={85} mainColor={'EB4558'} secondColor={'991626'}/></div>
                                    <div className="show col-6 d-flex align-items-center justify-content-center"><b>PHP CI Laravel</b></div>
                                </div>
                            </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={75} mainColor={'5BCE3D'} secondColor={'2C8614'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Fusion 360</b></div>
                            </div>
                        </div>
                    </div>

                    <div className="show row mt-5">
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={70} mainColor={'2E9B89'} secondColor={'0F6556'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>VHDL</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={80} mainColor={'F58E48'} secondColor={'9F4E17'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>PMP</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                    <div className="show col-6"><DonutChart value={80} mainColor={'5BCE3D'} secondColor={'2C8614'}/></div>
                                    <div className="show col-6 d-flex align-items-center justify-content-center"><b>JS Jquery React</b></div>
                                </div>
                            </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={80} mainColor={'EB4558'} secondColor={'991626'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Milling Machining</b></div>
                            </div>
                        </div>
                    </div>


                    <div className="show row mb-4 mt-5">
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={75} mainColor={'5BCE3D'} secondColor={'2C8614'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Embedded Linux</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={80} mainColor={'EB4558'} secondColor={'991626'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>Design thinking</b></div>
                            </div>
                        </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                    <div className="show col-6"><DonutChart value={80} mainColor={'2E9B89'} secondColor={'0F6556'}/></div>
                                    <div className="show col-6 d-flex align-items-center justify-content-center"><b>Ionic React</b></div>
                                </div>
                            </div>
                        <div className="show col-3">
                            <div className="show row skillsAlign">
                                <div className="show col-6"><DonutChart value={90} mainColor={'F58E48'} secondColor={'9F4E17'}/></div>
                                <div className="show col-6 d-flex align-items-center justify-content-center"><b>3D Printing</b></div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>

    );
};

export default Skills;

