
type respond = {
    result?: apiRes,
    status?: string,
}

type apiRes = {
    status: string,
    message: string
}


export default class MailService {

    static sendMail(email: string, name: string, message: string):Promise<apiRes>{

        let body = {
            "email": email,
            "message": message,
            "name": name
        }

        return fetch('http://dev.sylvain-pierson.fr/sendmailapi.php', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {'Content-Type': 'application/json'}
            
        })
        .then(resp => resp.json())
        .catch(error => this.handleError(error));
        


    }

    static handleError(error: Error): void {
        console.error(error);
    }


}