import React, { FunctionComponent} from 'react'
import './resumecontent.css'


type Props={
    actif: boolean,
    offset: boolean,
  }


const Education: FunctionComponent<Props> = ({actif, offset}) => {
    

    return (
            <div className={(actif ? "show":"")}>
                <div className="boule show"></div>
                    {/* Right */}                
                <div className="show d-flex flex-row-reverse">
                    <div className={(offset ? "expItemRight show leftMinus":"expItemRight show")}>  
                            <div className="expHolderRight show">
                                <div className="head show">
                                    <div className="date-range show">2018-2019</div>
                                        <h3><b>Skema Business School</b></h3>
                                        <h4>Paris, France</h4>
                                    </div>
                                    <div className="body show">
                                    <p><b>Specialised Master : Project and Programme Management</b>
                                    <b>Agile Certification & PMP on doing</b></p>
                                    <p>Lead to a fine control of management techniques recognised in projects and programs and piloting projects in complex environments, multicultural and international.</p>
                                     </div>
                                </div>
                            </div>
                </div>

                    {/* Left */}
                <div className="show d-flex flex-row">
                        <div className="expItemLeft show">
                            <div className="expHolderLeft show">
                                <div className="head show">
                                    <div className="date-range show">2016-2018</div>
                                        <h3><b>ISEP</b></h3>
                                        <h4>Paris, France</h4>
                                    </div>
                                    <div className="body show">
                                    <p><b>Engineering School with Embedded System as Major</b></p>
                                    <p>The embedded systems major addresses the design, the implementation and the management of complex systems</p>
                                     </div>
                                </div>
                            </div>
                </div>

                    {/* Right */}                
                    <div className="show d-flex flex-row-reverse">
                        <div className={(offset ? "expItemRight show leftMinus":"expItemRight show")}>
                            <div className="expHolderRight show">
                                <div className="head show">
                                    <div className="date-range show">2013-2016</div>
                                        <h3><b>Polytech Nice-sophia</b></h3>
                                        <h4>Sophia-Antipolis, France</h4>
                                    </div>
                                    <div className="body show">
                                    <p><b>Electronic Engineering</b></p>
                                     </div>
                                </div>
                            </div>
                </div>

            </div>
    );
};

export default Education;

