import React, { FunctionComponent } from 'react'
import './aboutme.css'

import developer from "../ico/icons8-developer-50.png"
import electronics from "../ico/icons8-electronics-50.png"
import project from "../ico/icons8-project-management-50.png"
import solidworks from "../ico/icons8-solidworks-50.png"

import funpicture from "../images/funpicture.jpeg"

const Aboutme: FunctionComponent = () => {


    return (

        <div id="Aboutme" className="aboutme">
            <div className="d-flex flex-column justify-content-between aboutmeFlex">
                <div className="aboutmeTitle"><span style={{ color: 'black' }}>About</span> <span style={{ color: '#eb4559' }}>Me :</span>
                </div>
                <div className="aboutmeCenter h-100">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-5 d-none d-lg-block">
                                <div className="aboutmePicture" style={{ backgroundImage: `url(${funpicture})` }}>

                                </div>

                            </div><b></b>
                            <div className="col-12 col-lg-7 ">
                                <div className="aboutmeText">
                                    <span><h1>Bringing Passion to</h1> </span><span><h1 style={{ color: '#eb4559' }}><i>&nbsp;Everything I Do&nbsp;</i></h1></span>
                                    <b></b>
                                    <p className="aboutmeTextD ml-2 pt-3">
                                        <b>Project Manager</b> with an <b>Engineering degree</b> in <b>embedded electronics</b> and a Master's degree in a <b>Business School</b> with concentration in <b>Project Management</b>.                     
                                    <b>Developer</b> with extensive experience doing freelance web and mobile development work for startups.</p><p>
                                    It is my passion to build the <b>electronics projects</b> of <b>tomorrow</b>.
                                </p>
                                    <div className="row">
                                        <div className="col-12 col-md-6 pb-3 mt-3 aboutmeIco"><img src={electronics} className="" alt="Electronics Engineer" /><span>Electronics Engineer</span></div>
                                        <div className="col-12 col-md-6 pb-3 pt-3 aboutmeIco"><img src={project} className="" alt="Project Manager" /><span>Project Manager</span></div>
                                        <div className="col-12 col-md-6 pt-3 aboutmeIco"><img src={developer} className="" alt="Web Developer" /><span>Web & mobile Developer</span></div>
                                        <div className="col-12 col-md-6 pt-3 aboutmeIco"><img src={solidworks} className="" alt="Industrial Designer" /><span>Industrial Designer</span></div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutmeBottom text-center">
                </div>

            </div>

        </div>



    );
};

export default Aboutme;

