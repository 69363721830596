import React, { FunctionComponent} from 'react'
import './resumecontent.css'

import mb from "../../images/mb.jpg";
import gloves from "../../images/gloves.jpg";
import work from "../../images/work.jpg";
import jetski from "../../images/jetski.jpg";
import aircraft from "../../images/aircraft.jpg";
import gears from "../../images/gears.jpg";


type Props={
    actif: boolean,
  }


const Personal: FunctionComponent<Props> = ({actif}) => {
    

    return (
        <div className={(actif ? "show":"")}>
                <div className="show container-fluid">
                    <div className="show text-center personalTitle">
                        <h2>Personal Interest & Passions</h2>
                        <hr></hr>
                    </div>
                    <div className="show row mt-4">
                        <div className="show col-4 personalElem">
                            <div className="show" style={{backgroundImage: `url(${gears})`}}>
                                <div className="show d-flex flex-row align-items-center justify-content-center">
                                    <h3>Mechanics sports</h3>
                                </div>
                            </div>
                        </div>
                            <div className="show col-4 personalElem">
                                <div className="show" style={{backgroundImage: `url(${gloves})`}}>
                                    <div className="show d-flex flex-row align-items-center justify-content-center">
                                        <h3>DIY</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="show col-4 personalElem">
                                <div className="show" style={{backgroundImage: `url(${jetski})`}}>
                                    <div className="show d-flex flex-row align-items-center justify-content-center">
                                        <h3>Nautical sports</h3>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="show row mt-4">
                        <div className="show col-4 personalElem">
                                <div className="show" style={{backgroundImage: `url(${work})`}}>
                                    <div className="show d-flex flex-row align-items-center justify-content-center">
                                        <h3>Informatics</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="show col-4 personalElem">
                                <div className="show" style={{backgroundImage: `url(${aircraft})`}}>
                                    <div className="show d-flex flex-row align-items-center justify-content-center">
                                        <h3>Planes</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="show col-4 personalElem">
                                <div className="show" style={{backgroundImage: `url(${mb})`}}>
                                    <div className="show d-flex flex-row align-items-center justify-content-center">
                                        <h3>Electronics</h3>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            


            

    );
};

export default Personal;

