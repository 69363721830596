import React, { FunctionComponent, useState, useEffect } from 'react'


const TitleEffect: FunctionComponent = () => {

    const data = [' Web Developer', ' Project Manager', ' Electronic Engineer', ' Freelancer' ];
    const [word,setWord]=useState<number>(0);
    const [text,setText]=useState<string>(" ");
    const [up,setUp]=useState<boolean>(true);
    const [cursor,setCursor]=useState<number>(1);

    function blink(){
        if(cursor){
            setCursor(0)
        }
        else{
            setCursor(1)
        }
    }

function mainEffect(){
    const currentIdx = text.length;
    if(up){
        setText(data[word].slice(0,currentIdx+1));
            if(currentIdx == ((data[word].length))){
        setUp(false)
            } 
    }
    else{
            if(currentIdx == 1){
                setText(" ");
                setUp(true)
                if(word < 3 ){
                    setWord(word +1);
                }
                else{
                    setWord(0);
                }
               
            }
            else{
            setText(data[word].slice(0,currentIdx-1));
        }
    }

}

useEffect(() => {
    var randomTime = Math.floor(Math.random() * 150 ) + 50;
    const currentIdx = text.length;
    if(currentIdx == ((data[word].length))){
        randomTime = 700;
    } 
    const timer = setTimeout(function(){ mainEffect()}, randomTime);
    return () => clearTimeout(timer);
   }, [text,word,up]);


useEffect(() => {setTimeout(function(){ blink()}, 600);}, [cursor]);


    return (
        <div style={{minHeight: "84px"}}>
           <span>{text}</span><span style={{opacity: cursor}}>|</span>
        </div>
    );
};

export default TitleEffect;
