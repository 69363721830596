import React, { FunctionComponent, useState } from 'react'

import './contact.css'

import MailService from '../../../services/mailService';

import ReCAPTCHA from "react-google-recaptcha";
import { Map, TileLayer, Marker, Popup, } from 'react-leaflet'
import L from 'leaflet';


import 'leaflet/dist/leaflet.css';

type Field = {
    value?: any,
    isValid?: boolean
}
type Form = {
    name: Field,
    email: Field,
    message: Field
}

type Message = {
    alerte?: boolean,
    good?: boolean,
}

const Contact: FunctionComponent = () => {

    //const history = useHistory();

    const [form, setForm] = useState<Form>({
        name: { value: '', isValid: true },
        email: { value: '', isValid: true },
        message: { value: '', isValid: true },
    });

    const [envoi, setEnvoi] = useState<Message>({
        alerte: false,
        good: false,
    });

    const [captcha, setCaptcha] = useState<boolean>(false);

    function onChange(value: any) {
        setCaptcha(true);
    }

    const position = [32.7174209, -117.1627714];
    const maposition = [32.827637, -117.139037];

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        MailService.sendMail(form.email.value, form.name.value, form.message.value).then(dataReturn => {
            if (dataReturn.status === "success") {
                //Cacher send
                const newField: Message = { good: true }

                setEnvoi({ ...envoi, ...newField })
            }
        })
    }

    const onEditFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField: Field = { [fieldName]: { value: fieldValue } }
        setForm({ ...form, ...newField });
    }

    const iconPerson = new L.Icon({
        iconUrl: require('../ico/location.png'),
        iconRetinaUrl: require('../ico/location.png'),
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(40, 40),
    });




    return (

        <div id="Contact" className="contact">
            <div className="d-flex flex-column justify-content-between contactFlex">
                <div className="contactTitle"><span style={{ color: 'black' }}>C</span><span style={{ color: '#eb4559' }}>ontact :</span></div>
                <div className="contactCenter h-100">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6 d-none d-lg-block icoContact">

                                <Map center={position} zoom={11}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                    />
                                    <Marker position={maposition} icon={iconPerson}>
                                    </Marker>
                                </Map>

                                <div className="text-center contactLocation"><h4>San Diego, CA, USA</h4></div>

                            </div>
                            <form className="col-12 col-lg-6" onSubmit={e => onSubmit(e)}>


                                <p className="mailAreaTitle">Name :</p>
                                <p><input type="text" name="name" aria-describedby="Name" value={form.name.value} onChange={e => onEditFormInput(e)} /></p>
                                <p className="mailAreaTitle">Email :</p>
                                <p><input type="email" name="email" aria-describedby="Email" value={form.email.value} onChange={e => onEditFormInput(e)} /></p>
                                <p className="mailAreaTitle">Message :</p>
                                <p><textarea className="form-control" name="message" rows={5} value={form.message.value} onChange={e => onEditFormInput(e)}></textarea></p>

                                <div className="text-center align-items-center">
                                    <div className={"g-recaptcha " + (captcha ? "hide" : "")}><ReCAPTCHA
                                        sitekey="6LfJTukUAAAAAGuNNFU5djcZNLfRQ5xSePISVF7Z" theme='light'
                                        //
                                        //clef perso
                                        onChange={onChange}
                                    />
                                    </div>
                                </div>

                                {!envoi.good &&
                                    <p className={"text-center align-items-center mailButton " + (captcha ? "show" : "")}>
                                        <button type="submit" className="btn btn-secondary btn-lg">Send</button>
                                    </p>
                                }
                                {envoi.good &&
                                    <div className="alert alert-success text-center" role="alert">
                                        Thanks you for your message !
                                    </div>
                                }
                                {envoi.alerte &&
                                    <div className="alert alert-danger text-center" role="alert">
                                        Une erreur c'est produite
                                    </div>
                                }

                            </form>
                        </div>
                    </div>
                </div>
                <div className="contactBottom text-center">
                </div>

            </div>

        </div>



    );
};

export default Contact;

