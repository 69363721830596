import React, { FunctionComponent, useRef, useLayoutEffect, useState } from 'react'
import './resume.css'

import icoedu from "../icon/edu.svg";
import icoexpe from "../icon/expe.svg";
import icome from "../icon/me.svg";
import icoskills from "../icon/skills.svg";

import downloadImg from "../ico/download.png";

import Education from "./resumecontent/education";
import Experience from "./resumecontent/experience";
import Personal from "./resumecontent/personal";
import Skills from "./resumecontent/skills";


type Props={
    resultat: number,
  }


const Resume: FunctionComponent<Props> = ({resultat}) => {
    
    const [element,setElement] = useState<number>(0);
    const [mobile,setMobile] = useState<boolean>((window.innerWidth < 992 ? true : false));

    const [offset,setOffset] = useState<boolean>(window.innerWidth % 2 ? false : true);

    
    function flecheDroite(){

        const etat = element + 1;
        if(etat > 3){
            setElement(0); 
        }
        else{
            setElement(etat); 
        }
    }

    function flecheGauche(){

        const etat = element - 1;
        if(etat < 0){
            setElement(3); 
        }
        else{
            setElement(etat); 
        }
    }

    window.addEventListener('resize', updateDisplay)
    function updateDisplay(){
        console.log(window.innerWidth)
        if(window.innerWidth < 1006){
            setMobile(true);
        }
        else{
            setMobile(false);
            if(window.innerWidth % 2){
                setOffset(false);
            }
            else{
                setOffset(true);
            }
        }
    }

    if(mobile){
        return (

            <div className="resume">
                <div className="d-flex flex-column justify-content-between resumeFlex">
                    <div className="resumeTitle"><span style={{color:'black'}}>R</span><span style={{color:'#eb4559'}}>esume :</span></div>
                    <div className="resumeCenter h-100">
                        <div className="container-fluid h-100">
                            <div className="align-items-center text-center">
                                <p>Sadly a phone screen is tool small for a good rendering.
                                But, you can still download my resume &#x1F60A;</p>
                                <div className="text-center pt-5">
                                    <a href=""><img src={downloadImg} className="iconMenuDesk" alt="Download my resume"/></a>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    <div className="resumeBottom text-center">
                    </div>
    
                </div>
                
            </div>
    
                
    
        );
    }
    else{
        return (

        <div className="resume">
            <div className="d-flex flex-column justify-content-between resumeFlex">
                <div className="resumeTitle"><span style={{color:'black'}}>R</span><span style={{color:'#eb4559'}}>esume :</span></div>
                <div className="resumeCenter h-100">
                    <div className="container-fluid h-100">
                        <div className="row align-items-center">
                            <div className={"col-1 flecheGauche" + (resultat>0.3 ? " bounceLeft":"")} onClick={() => flecheGauche()}>
                            </div>
                            <div className="col resumeContent text-center">
                            
                                <Experience actif={(element===0 ? true:false)} offset={offset}/>
                                <Education actif={(element===1 ? true:false)} offset={offset}/>
                                <Skills actif={(element===2 ? true:false)}/>
                                <Personal actif={(element===3 ? true:false)}/>
                                

                            </div>
                            <div className={"col-1 flecheDroite" + (resultat>0.3 ? " bounceRight":"")} onClick={() => flecheDroite()}>
                            </div>
                        </div>
                        </div>
                    </div>
                <div className="resumeBottom text-center">
                    <img src={icoexpe} alt="Icon experience" className={"p-2 ml-1 mr-1" + (element===0 ? " show":" unshow")} onClick={() => setElement(0)}/>
                    <img src={icoedu} alt="Icon education" className={"p-2 ml-1 mr-1" + (element===1 ? " show":" unshow")} onClick={() => setElement(1)}/>
                    <img src={icoskills} alt="Icon skills" className={"p-2 ml-1 mr-1" + (element===2 ? " show":" unshow")} onClick={() => setElement(2)}/>
                    <img src={icome} alt="Icon personal" className={"p-2 ml-1 mr-1" + (element===3 ? " show":" unshow")} onClick={() => setElement(3)}/>
                </div>

            </div>
            
        </div>

            

    );
    }
    
};

export default Resume;