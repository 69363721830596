import React, { FunctionComponent} from 'react'
import './button.css';
import styled from 'styled-components';


type Props={
    label: string,
    position: number,
    rank: number,
    setScroolTo: (setNewScroolTo: number) => void;
}





const Button: FunctionComponent<Props> = ({label,position,rank,setScroolTo}) => {

    
    
    var resultat=0;
    const wSize =window.innerHeight;
        const posInWindow = position-((rank - 0.5)*wSize);
        if(posInWindow >= 0 && posInWindow < wSize ){            
            if(posInWindow < (wSize/2)){
                resultat = Math.pow(((posInWindow/(wSize/2))),2);
            }
            else{
                resultat = Math.pow((1-(posInWindow-(wSize/2))/(wSize/2)),2);
            }

        }
  
    
    const PButton = styled.p`
    border-bottom: 2px solid lightgrey;
    transition: all 0.25s linear;
    position: relative;
    :before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: #eb4559;
        position: absolute;
        left: 0;
        bottom: -3px; /* this is to match where the border is */
        transform-origin: left; 
        transform: scale(${(props: {position: number}) => resultat});
        transition: 0.15s linear;
    }
    :active:before {
        transform: scale(1);
    }` 

    const moveTo =() => {
        const wSize =window.innerHeight;
        switch (label) {
            case "About Me":
                setScroolTo(wSize);
                break;
            case "Resume":
                setScroolTo(2*wSize);
                break;
            case "Portfolio":
                setScroolTo(3*wSize);
                break;
            case "Contact":
                setScroolTo(4*wSize);
                break;
            default:
                break;
        }
    }



    return (
        <div className={"lienButton"} style={{transform: "50px"}} onClick={() => moveTo()}>
            <PButton position={resultat}>{label}</PButton>     
        </div>
            
    );
};

export default Button
