import React, { FunctionComponent} from 'react'
import './main.css'

import Landing from './elements/landing';
import Aboutme from './elements/aboutme';
import Resume from './elements/resume';
import Portfolio from './elements/portfolio';
import Contact from './elements/contact';

type Props={
    position: number,
  }


const Main: FunctionComponent<Props> = ({position}) => {    

    var resultat=0;
    const wSize =window.innerHeight;
    const posInWindow = position-((2 - 0.5)*wSize);
    if(posInWindow >= 0 && posInWindow < wSize ){            
        if(posInWindow < (wSize/2)){
            resultat = Math.pow(((posInWindow/(wSize/2))),2);
        }
        else{
            resultat = Math.pow((1-(posInWindow-(wSize/2))/(wSize/2)),2);
        }

    }



    return (

            <div className="flex-fill mainData">
                <div className="mainContent h-100">
                    <Landing/>
                    <Aboutme/>
                    <Resume resultat={resultat}/>
                    <Portfolio/>
                    
                    <Contact/>
                </div>
                

        </div>
            

    );
};

export default Main;

