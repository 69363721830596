import React, { FunctionComponent} from 'react'
import './mainMenuDesktop.css';
import mainPicture from '../../components/main/images/mainpicture.jpg';

import Button from './components/button';

import linkedin_logo from "./ico/icons8-linkedin-2.svg"
import instagram_logo from "./ico/icons8-instagram-2.svg"
import skype_logo from "./ico/icons8-skype.svg"


type Props={
    menuPicture:boolean,
    position: number,
    setScroolTo: (setNewScroolTo: number) => void;
}

const MainMenuDesktop: FunctionComponent<Props> = ({menuPicture,position,setScroolTo}) => {



    return (
        <div className="d-none d-xl-block sidebar">

                <div className="border-right bg-white h-100">
                    <div className="container h-100 d-flex justify-content-center flex-column">
                        <div></div>
                        <div className="mb-auto mainPictureFrame">
                            <img src={mainPicture} className={"smallPicture " + (menuPicture ? "show" : "")} alt="mainPicture" />
                        </div>
                        <div className="pl-2 ">
                            <Button label={"About Me"} position={position} rank={1} setScroolTo={setScroolTo}/>
                            <Button label={"Resume"} position={position} rank={2} setScroolTo={setScroolTo}/>
                            <Button label={"Portfolio"} position={position} rank={3} setScroolTo={setScroolTo}/>
                            <Button label={"Contact"} position={position} rank={4} setScroolTo={setScroolTo}/>
                        </div>
                        <div className="mt-auto pb-5 d-flex justify-content-center">
                            <a href="https://www.linkedin.com/in/sylvain-pierson-9b2398124/"><img className="m-2 iconMenuDesk" src={linkedin_logo} alt="LinkedIn"/></a>
                            {/* <a href="https://www.instagram.com/sylv_ain_fr/"><img className="m-2 iconMenuDesk" src={instagram_logo} alt="Instagram"/></a> */}
                            <a href="skype:sylvain.pierson"><img className="m-2 iconMenuDesk" src={skype_logo} alt="Skype"/></a>
                            
                         
                        </div>
                        
                        

                    </div>

            </div>
        </div>
            
    );
};

export default MainMenuDesktop;

