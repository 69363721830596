import React, { FunctionComponent} from 'react'
import TitleEffect from './titleeffect';

import './landing.css'
import mainPicture from '../images/mainpicture.jpg';

import Background from "../images/mainbackground.jpg"


const Landing: FunctionComponent = () => {

    return (

        <div className="text-center d-flex flex-column justify-content-between landing" style={{backgroundImage: `url(${Background})`}}>
            <div style={{minHeight: "45px"}}></div>
            <div>
                <div>
                    <img src={mainPicture} className="mainPicture" alt="mainPicture" />
                </div>
                <div className="myName">
                    <h1><span style={{color:'white'}}>Sylvain</span> <span style={{color:'#eb4559'}}>Pierson</span></h1>
                </div>
                <div className="mySkills">
                    <TitleEffect/>
                </div>  
            </div>
            <div>
                <div className="arrow bounce"></div>
            </div>
        </div>

            

    );
};

export default Landing;

