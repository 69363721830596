import React, { FunctionComponent} from 'react'
import './portfolio.css'

import elecBk from "../images/elecBK.jpg";
import reactBk from "../images/reackBk.png";
import caoBk from "../images/caoBk.jpeg";
import embdBk from "../images/embdBk.jpeg";



const Portfolio: FunctionComponent = () => {

    return (

        <div className=" d-none d-lg-block portfolio">
            <div className="d-flex flex-column justify-content-between portfolioFlex">
                <div className="portfolioTitle"><span style={{color:'black'}}>P</span><span style={{color:'#eb4559'}}>ortfolio :</span>
                </div>
                <div className="h-100">
                    <div className="container-fluid ">
                        <div className="row h-35vh portfolioCenter">
                            <div className="col-12 col-lg-7">
                                    <div className="hvr-sweep-to-right" style={{backgroundImage: `url(${elecBk})`}}>
                                        <div className="portfolioText text-center">
                                            <h2><i>Custom PCB</i></h2>
                                            <p></p>
                                            <p>Motherboard for a Nixie Clock</p>
                                            <p>Analogic to digital log interface</p>
                                            <p>Stepper driver interface</p>
                                        </div>
                                    </div>
                            </div>
                                <div className="col-12 col-lg-5">
                                    <div className="hvr-sweep-to-left" style={{backgroundImage: `url(${reactBk})`}}>
                                        <div className="portfolioText text-center">
                                            <h2>Web & mobile development</h2>
                                            <p></p>
                                            <p>Survey web interface via mail</p>
                                            <p>API for embedded devices</p>
                                            <p>Android interactive directory</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="row h-35vh portfolioCenter">
                            <div className="col-12 col-lg-5">
                                <div className="hvr-sweep-to-right" style={{backgroundImage: `url(${caoBk})`}}>
                                    <div className="portfolioText text-center">
                                            <h2>Computer aided design</h2>
                                            <p></p>
                                            <p>Enclosure for PCB</p>
                                            <p>3D printers parts</p>
                                            <p>RC Parts</p>
                                        </div>
                                </div>
                            </div>
                                <div className="col-12 col-lg-7">
                                    <div className="hvr-sweep-to-left" style={{backgroundImage: `url(${embdBk})`}}>
                                        <div className="portfolioText text-center">
                                            <h2>Embedded electronic software</h2>
                                            <p></p>
                                            <p>Realtime Aquisition</p>
                                            <p>Sigfox data communications</p>
                                            <p>NFC reader</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                </div>
                <div className="portfolioBottom text-center">
                </div>

            </div>
            
        </div>


            

    );
};

export default Portfolio;

