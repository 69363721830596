import React, { FunctionComponent, useState,} from 'react';
import './App.css';

import MainMenuMobile from './components/menuMobile/mainMenuMobile';
import MainMenuDesktop from './components/menuDesktop/mainMenuDesktop';
import Main from './components/main/main';



var Scroll = require('react-scroll');


const App: FunctionComponent = () => {



  const wSize =window.innerHeight;

  const [menuPicture,setmenuPicture] = useState<boolean>(false);
  const [windowSize,setWindowSize] = useState<number>(0);

  window.addEventListener('scroll', function(e) {
    if(window.scrollY>(0.7*wSize)){
      setmenuPicture(true);
    }
    else{
      setmenuPicture(false);
    }
    setWindowSize(window.scrollY);
  });

      function setScroolTo(setNewScroolTo: number){
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(setNewScroolTo, {
            duration: 1500,
            delay: 100,
            smooth: "easeInOutQuint"});

      }

  return (
      <div className="h-100">
        <MainMenuMobile position={windowSize} setScroolTo={setScroolTo}/>
        <div className="d-flex">
          <MainMenuDesktop menuPicture={menuPicture} position={windowSize} setScroolTo={setScroolTo}/>
          <Main position={windowSize}/>
        </div>
        
      
      </div>
      
  );
}

export default App;
