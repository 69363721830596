import React, { FunctionComponent, useState} from 'react';
import './mainMenuMobile.css';

import { Collapse } from 'react-bootstrap';
import { HamburgerButton } from 'react-hamburger-button';

type Props={
    position: number,
    setScroolTo: (setNewScroolTo: number) => void;
}


const MainMenuMobile: FunctionComponent<Props> = ({position,setScroolTo}) => {
    const [open, setOpen] = useState(false);
    const [hamopen, hamsetOpen] = useState(false);
    const [offset,setOffset] = useState<number>((window.innerWidth < 992 ? 41 : 0));

    const wSize = window.innerHeight;

    window.addEventListener('resize', updateDisplay)
    function updateDisplay(){

        if(window.innerWidth < 768){
            setOffset(41);
        }
        else{
            setOffset(0);
        }
    }

    function onClick(){
        hamsetOpen(!hamopen);
        setOpen(!open);
    }

    const moveTo = (label:string) => {
        const wSize =window.innerHeight;
        switch (label) {
            case "About Me":
                onClick();
                setScroolTo(wSize + offset);
                break;
            case "Resume":
                onClick();
                setScroolTo(2*wSize  + offset);
                break;
            case "Contact":
                onClick();
                setScroolTo(3*wSize  + offset);
                break;
            default:
                break;
        }
    }

    return (
        <div className="d-block d-xl-none menuMobile">
            <nav className="navbar navbar-dark bg-dark">
                <a className="navbar-brand"></a>
                <div className="button">
                  <HamburgerButton
                    open={hamopen}
                    onClick={() => onClick()}
                    width={25}
                    height={15}
                    strokeWidth={2}
                    color='white'
                    animationDuration={0.5}
                />  
                </div>
                
                <Collapse in={open}>
                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            <li className={"nav-item " + ((position > wSize/2 && position < 1.5 * wSize) ? "active" : "")}>
                            <a className="nav-link" onClick={() => moveTo("About Me")}>About Me</a>
                            </li>
                            <li className={"nav-item " + ((position > 1.5 * wSize && position < 2.5 * wSize) ? "active" : "")}>
                            <a className="nav-link" onClick={() => moveTo("Resume")}>Resume</a>
                            </li>
                            <li className={"nav-item " + ((position > 2.5 * wSize) ? "active" : "")}>
                            <a className="nav-link" onClick={() => moveTo("Contact")}>Contact</a>
                            </li>
                        </ul>
                    </div> 
                </Collapse>
                
                </nav>
        </div>
    );

}

export default MainMenuMobile;

