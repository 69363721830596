import React, { FunctionComponent } from 'react';
import './donuts.css'


type Props={
  value: number,        // value the chart should show
  size?: number,         // diameter of chart
  strokewidth?: number   // width of chart line
  mainColor?: string   // width of chart line
  secondColor?: string   // width of chart line
}

const DonutChart: FunctionComponent<Props> = ({value, size=60, strokewidth=13, mainColor='#eb4559', secondColor='#F596A1'}) => {

      const halfsize = (size * 0.5);
      const radius = halfsize - (strokewidth * 0.5);
      const circumference = 2 * Math.PI * radius;
      const strokeval = ((value * circumference) / 100);
      const dashval = (strokeval + ' ' + circumference);
  
      const trackstyle = {strokeWidth: strokewidth, stroke: secondColor};
      const indicatorstyle = {strokeWidth: strokewidth, strokeDasharray: dashval, stroke: mainColor}
      const rotateval = 'rotate(-90 '+halfsize+','+halfsize+')';
  
      return (
        <svg width={size} height={size} className="donutchart">
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track"/>
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator"/>
          <text className="donutchart-text" x={halfsize} y={halfsize+5} style={{textAnchor:'middle'}} >
            <tspan className="donutchart-text-val">{value}</tspan>
            <tspan className="donutchart-text-percent">%</tspan>
          </text>
        </svg>
    );
  };
  
  export default DonutChart;
  
  